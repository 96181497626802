.fuelStoragePage,
.fuelStorage,
.tankWrap,
.tank {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    box-sizing: border-box;
}
.fuelStoragePage,
.tankWrap {
    width: 100%;
}
.fuelStorage {
    width: 100%;
}
.fuelStoragePage h2 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 2vw;
    color: #252525;
    font-weight: 500;
    margin-bottom: 1vh;
}
.tankWrap {
    display: grid;
    width: 100%;
    align-content: space-between;
    grid-template-columns: auto auto auto;
    column-gap: 2vw;
    row-gap: 2vw;
}
.tank {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 1vw;
    border-radius: 10px;
}
.tank h6, 
.tank h5 {
    font-weight: 500;
    font-size: 1.2vw;
    color: #252525;
    margin: 0;
    padding: 0;
}
.tank h6 span, 
.tank h5 span {
    font-weight: 400;
    font-size: .8vw;
    color: lightgray;
}
.tank input {
    width: 100%;
    margin: 2vh 0;
}